<script lang="ts">
  import { onMount } from "svelte";
  import { dataset_dev } from "svelte/internal";
  import { allEditable, getDataForCleanup, onlyEditable } from "../services/cleanup";
  import { getNameQuestionIndexes, getQuestions } from "../services/project";
  import { isAdmin, isSuperAdmin } from "../stores/store";
  import { readable_question } from "../utils/readable_question";
  import Loading from "./Loading.svelte";
  import QuestionRow from "./QuestionRow.svelte";
  export let projectId;
  let cleanupData;
  let filteredData;
  let questions;
  let dangerEnabled = false;
  let dataStrings = [];


  const loadData = async () => {
    questions = await getNameQuestionIndexes(projectId);
    console.log('questions', questions);
    cleanupData = await getDataForCleanup(projectId);
    filteredData = await onlyEditable(cleanupData, questions);
    console.log('cleanup', cleanupData);
    console.log('filtered', filteredData);
    dataStrings = [];

    //let allData = await allEditable(cleanupData);
    //console.log(allData);
   
    //gets the positions of each answer so can delete them
    cleanupData.forEach((userData, userIndex) => {
    let userAnswers = [];

    userData.data.forEach((setData, setIndex) => {
        for (const [setKey, questionsSet] of Object.entries(setData)) {
            for (const [questionKey, questionData] of Object.entries(questionsSet)) {
                if (questionData && questions.includes(questionData.identifier)) {
                    if (questionData.hasOwnProperty('answers') && Array.isArray(questionData.answers)) {
                        const processAnswers = (answers, questionId, questionKey) => {
                            answers.forEach((answer, position) => {
                                if (Array.isArray(answer)) {
                                    // Process nested answers
                                    answer.forEach((nestedAnswer, innerIndex) => {
                                        if (typeof nestedAnswer === 'object' && nestedAnswer.data !== undefined) {
                                            userAnswers.push({
                                                id: questionId,
                                                position: position,
                                                index: questionKey,
                                                innerIndex: innerIndex
                                            });
                                        }
                                    });
                                } else if (typeof answer === 'object' && answer.data !== undefined) {
                                    userAnswers.push({
                                        id: questionId,
                                        position: position,
                                        index: questionKey,
                                        innerIndex: -1 // Not in a nested array
                                    });
                                } else if (typeof answer === 'string' && answer.length > 0) {
                                    userAnswers.push({
                                        id: questionId,
                                        position: position,
                                        index: questionKey,
                                        innerIndex: -1 // Not in a nested array
                                    });
                                }
                            });
                        };

                        processAnswers(questionData.answers, questionData.identifier, questionKey);
                    } else {
                        console.log('No valid answers for', questionData.identifier);
                    }
                } else {
                    console.log('Invalid or missing identifier at', questionKey);
                }
            }
        }
    });

    dataStrings.push(userAnswers);
});
   
  };

  const updated = () => {
    loadData();
  };
  onMount(async () => {
    await loadData();
  });

</script>

<div class="panel" class:show-danger={dangerEnabled}>
  {#if filteredData}
    <table class="tight-input">
      <thead>
        {#if $isAdmin}
        <th class="is-narrow" />
        <th class="is-narrow" />
        <th width="50%">Current</th>
        <th width="50%">New entry</th>
        <th class="is-narrow no-edge" />
        <th class="is-narrow" />
        <th
          class="is-danger"
          colspan="2"
          on:click={() => {
            dangerEnabled = !dangerEnabled;
          }}>Danger zone</th
        >
        {:else}
            <th class="is-narrow"/>
            <th width="50%">Answer</th>
        {/if}
      </thead>
      <tbody>
        {#each filteredData as c, index (index)}
          {#each c.data as item, innerIndex}
            <QuestionRow
              rowsInBlock={c.data.length}
              firstRowInBlock={innerIndex === 0}
              finalRowInBlock={innerIndex + 1 >= c.data.length}
              {item}
              user={c.user}
              {projectId}
              questionAllData={dataStrings[index][innerIndex]}
              {updated}
            
            />

          {/each}
        {/each}
      </tbody>
    </table>
  {:else}
    <Loading />
  {/if}
</div>

<style lang="scss">
</style>
